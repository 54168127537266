import { assign } from 'xstate';
import { SAVE_DOCUMENT_DATA } from '../../../../queries_mutations/mutations';
import docMutation from '../../../../queries_mutations/Documents/mutations';
import docQuery from '../../../../queries_mutations/Documents/queries';
import { readFile } from '../../../../shared/functions';

function createGaurds() {
  return {};
}

function createServices(client) {
  const { createDocumentUploadUrl, saveURLToDB, deleteDocument } =
    docMutation(client);
  const { fetchDocuments } = docQuery(client);

  return {
    saveDocumentData: (ctx, e) => {
      return client
        .mutate({
          mutation: SAVE_DOCUMENT_DATA,
          variables: {
            data: {
              pageCount: parseInt(e.data.totalPages) || 0,
              fileCount: parseInt(e.data.totalFiles) || 0,
              documentsSentBy: e.data.reqDocsSendingType,
            },
            appId: ctx.applicationID,
          },
        })
        .then((data, error) => {
          if (error) throw new Error();
          const result =
            data.data.applications.application.appDocumentsInfo.createOrUpdate;
          return result;
        });
    },

    // FIRES WHEN UPLOAD BUTTON IS PUSHED ON UPLOAD FILE COMPONENT
    uploadingDocument: async (ctx, { files }) => {
      const apiCalls = await Promise.all(
        // GO OVER EACH FILE
        files.map(async f => {
          // GET URL FOR UPLOAD TO BUCKET
          const { data } = await createDocumentUploadUrl({
            variables: {
              fullPathName: `applications/${ctx.applicationID}/${f.name}`,
              contentType: f.type,
              applicationId: ctx.applicationID,
            },
          });

          // SET RETURN DATA TO URL VARIABLE
          const { createUploadUrl: url } =
            data.applications.me.application.appDocumentsInfo;
          // READ THE FILE UPLOADED AND SET TO BODY
          const body = await readFile(f);

          // UPLOAD FILE TO GCP
          return fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': f.type,
            },
            body,
          }).then(() => {
            // SAVE URL TO DATABASE TIED TO  DOCUMENTS MODEL
            return saveURLToDB({
              variables: {
                document: {
                  url: `applications/${ctx.applicationID}/${f.name}`,
                  name: f.name,
                  applicationDocumentsId: ctx.documentsDBRefId,
                },
                applicationId: ctx.applicationID,
              },
            });
          });
        }),
      );
      return apiCalls;
    },
    fetchDocuments: ctx => {
      return fetchDocuments({
        variables: {
          applicationId: ctx.applicationID,
        },
      });
    },
    deleteDocument: (ctx, e) => {
      return deleteDocument({
        variables: {
          applicationId: ctx.applicationID,
          document: { id: e.file.id, url: e.file.url },
        },
      });
    },
  };
}

function createActions() {
  return {
    loadStep3DataIntoContext: assign({
      documentsDBRefId: (_, e) => e.data.appDocumentsInfo.id,
      step3: (ctx, e) => {
        const { documentsSentBy, pageCount, fileCount, documents } =
          e.data.appDocumentsInfo;
        return {
          ...ctx.step3,
          data: {
            documents,
            documentsSentBy,
            pageCount,
            fileCount,
          },
          complete: documentsSentBy !== '',
        };
      },
    }),
    setStep3Data: assign({
      step3: (ctx, e) => {
        return { ...ctx.step3, data: { ...ctx.step3.data, ...e.data } };
      },
    }),
    setStep3Complete: assign({
      step3: ctx => {
        return { ...ctx.step3, complete: true };
      },
    }),
    saveDocumentsToContext: assign({
      step3: (ctx, e) => {
        return { ...ctx.step3, data: { ...ctx.step3.data, documents: e.data } };
      },
    }),
  };
}

function create(client) {
  return {
    actions: createActions(client),
    services: createServices(client),
    guards: createGaurds(),
  };
}

export default create;
