import React from 'react';
import { createRoot } from 'react-dom/client';

import { inspect } from '@xstate/inspect';
import * as Sentry from '@sentry/react';
import { inject } from '@vercel/analytics';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { debug, sentryConfig } from './envConfig';
import App from './App';
import ApolloAuthProvider from './components/auth/ApolloAuthProvider';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate';

if (debug.xstate) {
  inspect({
    url: 'https://statecharts.io/inspect', // (default)
    iframe: false, // open in new window
  });
}

// Vercel Analytics
inject();

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
});

Sentry.init({
  ...sentryConfig,
  attachStacktrace: true,
});

Sentry.addIntegration(posthog.sentryIntegration(posthog, 'GCE', 'gce-3e'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
    <PostHogProvider client={posthog}>
      <Auth0ProviderWithNavigate>
        <ApolloAuthProvider>
          <ToastContainer
            style={{
              lineHeight: '1.5',
              width: '50vw',
              maxWidth: '600px',
            }}
          />
          <App />
        </ApolloAuthProvider>
      </Auth0ProviderWithNavigate>
    </PostHogProvider>
  </Sentry.ErrorBoundary>,
);
