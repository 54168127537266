import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { formatBytes } from '../../../shared/functions';
import folderImage from '../../../assets/folder-fill.svg';

const FormUpload = ({ send, formState, currentSavedFiles }) => {
  // FILES READY TO BE UPLOADED
  const [myFiles, setMyFiles] = useState([]);

  // FILES NOT ABLE TO UPLOAD
  const [myRejects, setMyRejects] = useState([]);

  // USEEFFECT TO SEE IF FILES HAVE BEEN UPLOADED TO SET MYFILES BACK TO []
  useEffect(() => {
    setMyFiles([]);
  }, [currentSavedFiles]);

  // DROPPING INTO DROP AREA
  const onDropAccepted = useCallback(
    acceptedFiles => {
      setMyFiles(prev => [...prev, ...acceptedFiles]);
    },
    [myFiles],
  );

  // REJECT DROPPED FILE
  const onDropRejected = useCallback(
    fileRejections => {
      setMyRejects([...myRejects, ...fileRejections]);
    },
    [myRejects],
  );

  // UPLOAD BUTTON HANDLER
  const uploadFiles = async () => {
    send('uploadDocument', { files: myFiles });
  };

  // SETUP USEDROPZONE
  // LEFT THE ACCEPT COMMENTED FOR FUTURE USE OF IMAGES
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: 52428800,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['docx'],
    },
  });

  // CLICK HANDLER FOR X BUTTON ON FILE TO BE UPLOADED
  const removeFileForUpload = file => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  // CLICK HANDLER FOR X BUTTON ON REJECTED FILE
  const removeReject = file => () => {
    const newRejects = [...myRejects];
    newRejects.splice(newRejects.indexOf(file), 1);
    setMyRejects(newRejects);
  };

  // CLICK HANDLER TO DELETE FILE FROM BUCKET AND POSTGRESS DB
  const removeUploadedFile = file => {
    send('DELETE_DOCUMENT', { file });
  };

  // CREATE LIST ITEMS FOR ITEMS TO BE UPLOADED
  const files = myFiles.map(file => (
    <li key={file.path}>
      <p className="file-name">{file.name}</p>{' '}
      <p className="file-size">{formatBytes(file.size)}</p>
      <button
        className="DZC-remove"
        type="button"
        onClick={removeFileForUpload(file)}
        title="Remove File From Upload"
      >
        Remove File
      </button>
    </li>
  ));

  // CREATE LIST OF REJECTED ITEMS
  const fileRejections = myRejects.map(({ file, errors }) => (
    <li key={file.path}>
      <p className="file-name">{file.path}</p>{' '}
      <p className="file-size">{formatBytes(file.size)}</p>
      <button
        title="Remove File From Queue"
        className="DZC-remove"
        type="button"
        onClick={removeReject(file)}
      >
        Remove File
      </button>
      <ul className="errors">
        {errors.map(e => (
          <li key={e.code}>File must be a PDF</li>
        ))}
      </ul>
    </li>
  ));

  // CREATE LIST OF SUCCESSFULLY UPLOADED FILES THAT ARE ON THE DB
  let filesUploaded;
  if (currentSavedFiles) {
    filesUploaded = currentSavedFiles.map(file => (
      <li key={file.name}>
        <p className="file-name">{file.name}</p>{' '}
        <button
          className="DZC-remove"
          type="button"
          onClick={() => removeUploadedFile(file)}
          title="Delete File"
        >
          Remove File
        </button>
      </li>
    ));
  }

  return (
    <DropZoneContainer>
      <div className="DZC-text">
        <p>
          <strong>
            Upload your files <span>*</span>
          </strong>
        </p>

        <p>
          You may upload up to 10 files with this application. Each document
          must be less than 50MB and must be a PDF, JPEG, or JPG.
        </p>
      </div>
      <div {...getRootProps({ className: 'DZC-dropzone' })}>
        <input {...getInputProps()} />
        <img src={folderImage} alt="Folder" />
        <p>Drag & drop some files here</p>
        <p>
          OR <strong>click to upload</strong>
        </p>
      </div>
      <div className="DZC-uploaded-files">
        {files?.length > 0 && (
          <div>
            <p>
              <strong>Files To Upload</strong>
            </p>
            <ul className="accepted-files">{files}</ul>
          </div>
        )}
        {fileRejections?.length > 0 && (
          <>
            <p>
              <strong>Unsupported files</strong>
            </p>
            <ul className="rejected-files">{fileRejections}</ul>
          </>
        )}
        {currentSavedFiles?.length > 0 && (
          <>
            <p>
              <strong>Files Uploaded Successfully:</strong>
            </p>
            <ul className="uploaded-files">{filesUploaded}</ul>
          </>
        )}
        <div>
          {files.length > 10 && (
            <p className="DZC-error-message">
              You have exceeded the limit of 10 files, please remove some to
              continue.
            </p>
          )}
          {formState.matches('editing.stepThree.uploadingDocument') && (
            <p>Uploading files now...</p>
          )}
          {formState.matches('editing.stepThree.errorUploadingDocuments') && (
            <p>Error uploading...</p>
          )}
          <button
            type="button"
            className="DZC-upload"
            disabled={
              files.length > 10 ||
              fileRejections.length > 0 ||
              files.length === 0
            }
            onClick={() => uploadFiles()}
          >
            Upload
          </button>
        </div>
      </div>
    </DropZoneContainer>
  );
};

export default FormUpload;

const DropZoneContainer = styled.div`
  width: 100%;
  background: #f7fafc;
  padding: 40px;
  border-radius: 16px;
  .DZC-text {
    font-size: 16px;
    color: #0a1f44;
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
    }
    span {
      color: #d24012;
    }
  }
  .DZC-dropzone {
    border: 2px solid #c0c0c0;
    border-radius: 3px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    p {
      margin-top: 10px;
    }
  }
  .DZC-uploaded-files {
    margin-top: 50px;
    ul {
      list-style: none;
      margin: 0 0 30px;
      padding: 0;
      li {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        p.file-size {
          margin-left: auto;
        }
        button {
          margin-left: 15px;
        }
        ul.errors {
          margin-top: 5px;
          width: 100%;
          color: #d24012;
          li {
            margin-top: 0;
          }
        }
      }
      &.rejected-files {
        margin-top: 0;
        .file-name {
          color: #d24012;
        }
        .file-size {
          color: #d24012;
        }
      }
      &.uploaded-files {
        .file-name {
          margin-right: auto;
        }
      }
    }
    .DZC-remove {
      border: 0;
      border-radius: 50%;
      background: #cbd5e0;
      width: 18px;
      height: 18px;
      -webkit-appearance: none;
      transition: all 0.2s ease;
      cursor: pointer;
      font-size: 0;
      position: relative;
      &:before {
        content: '×';
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 24px;
      }
      &:hover {
        background: #d24012;
      }
    }
    .DZC-upload {
      border: solid 2px #14527f;
      padding: 12px;
      width: 164px;
      text-align: center;
      font-weight: 600;
      font-size: 15px;
      background-color: #14527f;
      color: #fff;
      transition: all ease 0.3s;
      cursor: pointer;
      margin-top: 30px;
      &:hover {
        background-color: #033659;
        border: solid 2px #033659;
      }
      &:focus {
        background-color: #68b6ec;
        border: solid 2px #68b6ec;
      }
      &:disabled {
        border: solid 2px #c0c0c0;
        padding: 12px;
        width: 164px;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        color: #c0c0c0;
        transition: all ease 0.3s;
        margin-top: 30px;
        background-color: #8d8d8d;
        cursor: not-allowed;
      }
    }

    .DZC-error-message {
      color: #d24012;
      margin-top: 15px;
    }
  }
`;
